<template>
  <b-modal
    ref="refModal"
    id="booking-attachments-modal"
    title="Booking Attach Files"
    ok-title="Upload"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    @ok="validateForm"
  >
    <validation-observer ref="bookingAttachmentsForm">
      <b-form id="add-booking-attachments">
        <validation-provider
          #default="{ errors }"
          name="Upload Files"
          rules="required"
        >
          <b-form-group
            label="Upload Files"
            label-for="booking-attachments"
          >
            <b-form-file
              id="booking-attachments"
              name="booking-attachments"
              :state="errors.length > 0 ? false : null"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              multiple
              v-model="bookingAttachments"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormFile } from 'bootstrap-vue';
import { computed, ref } from "@vue/composition-api";
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import useBookingAttachment from '@/modules/booking/composables/useBookingAttachment';
import vSelect from 'vue-select';
import store from '@/store';

export default {
  setup() {
    const { getBookingAttachments, uploadBookingAttachments } = useBookingAttachment();

    const bookingAttachmentsForm = ref(null);
    const refModal = ref(null);
    const bookingAttachments = ref([]);
    const bookingId = computed(() => store.getters['booking/booking'].id);

    const validateForm = (modalEvt) => {
      modalEvt.preventDefault();

      bookingAttachmentsForm.value.validate().then(async (valid) => {
        if (!valid) return;

        const response = await uploadBookingAttachments(bookingId.value, bookingAttachments.value);
        if (response !== null) await getBookingAttachments(bookingId.value);
        refModal.value.hide();
      });
    };

    return {
      // Reactive
      bookingAttachmentsForm,
      bookingAttachments,
      refModal,

      // Method
      validateForm,

      // Validator
      required
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormFile,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-booking-attachments {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>