<template>
  <div>
    <b-alert
      variant="danger"
      :show="booking === undefined"
    >
      <h4 class="alert-heading">Error fetching booking data</h4>
      <div class="alert-body">
        No booking found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'booking-list' }"
        >
          Booking List
        </b-link>
        for other bookings.
      </div>
    </b-alert>

    <b-alert
      variant="warning"
      :show="
        booking &&
        booking.id !== null &&
        booking.bookingStatusId === BOOKING_STATUS_EDITED_ID
      "
    >
      <h4 class="alert-heading">This booking has been edited</h4>
      <div class="alert-body">
        This is just a preview of the original booking. Check the
        <span
          class="font-weight-bolder"
          v-if="booking"
        >
          Booking #{{ booking.voucherNum }}
        </span>
        to see the new changes.
      </div>
    </b-alert>

    <template>
      <b-row
        v-if="booking"
        class="invoice-preview"
      >
        <b-col
          :lg="
            booking.bookingStatusId !== BOOKING_STATUS_EDITED_ID &&
            booking.bookingStatusId !== BOOKING_STATUS_CANCELLED_ID
              ? 8
              : 12
          "
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex flex-md-row flex-column justify-content-between align-items-md-center"
              >
                <!-- Voucher Number -->
                <b-col
                  md="6"
                  class="text-right text-md-left"
                >
                  <div class="d-flex d-row">
                    <b-badge
                      id="booking-type"
                      :variant="`light-${
                        getBookingTypeVariantAndName(booking.bookingType)
                          .variant
                      }`"
                      class="mb-1 mr-50"
                    >
                      {{
                        getBookingTypeVariantAndName(booking.bookingType).name
                      }}
                    </b-badge>
                    <b-tooltip
                      target="booking-type"
                      placement="top"
                    >
                      <p class="mb-0">Booking Type</p>
                    </b-tooltip>

                    <b-badge
                      id="booking-status"
                      :variant="`${getBookingStatusVariant(
                        booking.bookingStatusId
                      )}`"
                      class="mb-1 ml-50"
                    >
                      {{ booking.bookingStatus.name }}
                    </b-badge>
                    <b-tooltip
                      target="booking-status"
                      placement="top"
                    >
                      <p class="mb-0">Booking Status</p>
                    </b-tooltip>
                  </div>

                  <h4>
                    Voucher
                    <span class="font-weight-bolder"
                      >#{{ booking.voucherNum }}</span
                    >
                  </h4>
                </b-col>

                <!-- Booking Date Added -->
                <b-col
                  md="6"
                  class="booking-wrap mt-md-0 mt-1"
                >
                  <div
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Date Added:</h6>
                    <p class="pl-25 mb-25">
                      {{ dateFormated(booking.bookingDateAddedUtc) }}
                    </p>
                  </div>
                </b-col>
              </div>

              <div
                class="d-flex flex-md-row flex-column justify-content-md-between mt-25"
              >
                <!-- Booking Source Type -->
                <b-col
                  md="6"
                  order="2"
                  order-md="1"
                  class="booking-wrap"
                >
                  <div
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Source:</h6>
                    <p class="pl-25 mb-25">
                      {{ booking.bookingSourceType.name }}
                    </p>
                  </div>
                </b-col>

                <!-- Booking Date -->
                <b-col
                  md="6"
                  order="1"
                  order-md="2"
                  class="booking-wrap"
                >
                  <div
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Booking Date:</h6>
                    <p class="pl-25 mb-25">
                      {{ dateFormated(booking.bookedForDateUtc) }}
                    </p>
                  </div>
                </b-col>
              </div>

              <div
                class="d-flex flex-md-row flex-column justify-content-md-between mt-25"
              >
                <b-col
                  md="6"
                  class="booking-wrap"
                  order="1"
                  order-md="2"
                >
                  <div
                    class="d-flex align-items-center justify-content-md-between mb-75"
                    v-if="booking.issuedByStaffMember !== null"
                  >
                    <h6 class="booking-label mb-25">Issued by:</h6>
                    <p class="pl-25 mb-25">
                      {{
                        `${booking.issuedByStaffMember.firstname} ${booking.issuedByStaffMember.lastname}`
                      }}
                    </p>
                  </div>

                  <div
                    class="d-flex align-items-center justify-content-md-between mb-75"
                    v-if="booking.dispatchedByStaffMember !== null"
                  >
                    <h6 class="booking-label mb-25">Dispatched by:</h6>
                    <p class="pl-25 mb-25">
                      {{
                        `${booking.dispatchedByStaffMember.firstname} ${booking.dispatchedByStaffMember.lastname}`
                      }}
                    </p>
                  </div>
                </b-col>

                <b-col
                  md="6"
                  class="booking-wrap"
                  order="2"
                  order-md="1"
                >
                  <div
                    v-if="booking.companyId"
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Company:</h6>
                    <p class="pl-25 mb-25">{{ booking.company.name }}</p>
                  </div>

                  <div
                    v-if="booking.companyContact"
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Contact:</h6>
                    <p class="pl-25 mb-25">
                      {{ `${booking.companyContact}` }}
                    </p>
                  </div>
                </b-col>
              </div>

              <div
                class="d-flex flex-md-row flex-column justify-content-md-between mt-25"
              >
                <b-col
                  md="6"
                  class="booking-wrap"
                >
                  <div
                    v-if="booking.cruiseLinerVessel !== null"
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Cruise Vessel:</h6>
                    <p class="pl-25 mb-25">{{ booking.cruiseLinerVessel }}</p>
                  </div>
                </b-col>

                <!-- Booking Crew Members -->
                <b-col
                  cols="12"
                  md="6"
                  class="booking-wrap"
                >
                  <div
                    v-if="booking.crewMembers !== null"
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Crew:</h6>
                    <p class="pl-25 mb-25">
                      {{ booking.crewMembers }}
                    </p>
                  </div>
                </b-col>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <b-card-body
              class="invoice-padding"
              v-if="!booking.bookingType.isCancelled"
            >
              <div
                class="d-flex flex-md-row flex-column justify-content-between pt-50"
              >
                <b-col
                  md="6"
                  order="1"
                  order-md="2"
                  class="booking-wrap mb-1"
                >
                  <!-- Passengers -->
                  <div
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Passengers:</h6>
                    <p class="pl-25 mb-25">{{ booking.passengerNum }}</p>
                  </div>

                  <!-- Baggage Number -->
                  <div
                    v-if="booking.baggageNum"
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Baggages:</h6>
                    <p class="pl-25 mb-25">{{ booking.baggageNum }}</p>
                  </div>

                  <!-- GHA Approved Name -->
                  <div
                    v-if="booking.ghaApprovalName"
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Approved by:</h6>
                    <p class="pl-25 mb-25">{{ booking.ghaApprovalName }}</p>
                  </div>

                  <!-- GHA Patient Name -->
                  <div
                    v-if="booking.ghaPatientName"
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Patient Name:</h6>
                    <p class="pl-25 mb-25">{{ booking.ghaPatientName }}</p>
                  </div>

                  <!-- Baggage Weight -->
                  <div
                    v-if="booking.heavyBaggageKg"
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Baggage:</h6>
                    <p class="pl-25 mb-25">{{ booking.heavyBaggageKg }} Kg</p>
                  </div>

                  <!-- Wheelchair Number -->
                  <div
                    v-if="booking.wheelchairNum"
                    class="d-flex align-items-center justify-content-md-between mb-75"
                  >
                    <h6 class="booking-label mb-25">Wheelchairs:</h6>
                    <p class="pl-25 mb-25">{{ booking.wheelchairNum }}</p>
                  </div>
                </b-col>

                <b-col
                  md="6"
                  order="2"
                  order-md="1"
                >
                  <!-- Pick Up Point -->
                  <app-timeline>
                    <app-timeline-item
                      :title="booking.pickupPoiName"
                      subtitle="Pick Up Point"
                      variant="primary"
                    />

                    <!-- Way Points -->
                    <template v-if="booking.includeWaypointPois">
                      <app-timeline-item
                        v-for="wayPoint in booking.bookingWaypointPois"
                        :key="wayPoint.id"
                        :title="wayPoint.name"
                        subtitle="Way Point"
                        variant="secondary"
                      />
                    </template>

                    <!-- Drop Off Point -->
                    <app-timeline-item
                      :title="booking.dropoffPoiName"
                      subtitle="Drop Off Point"
                      variant="primary"
                    />
                  </app-timeline>
                </b-col>
              </div>
            </b-card-body>

            <b-table-lite
              v-if="!booking.bookingType.isCancelled"
              responsive
              :items="booking.bookingTaxis"
              :fields="['driver', 'vehicle', 'rate']"
            >
              <template #cell(driver)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ `${data.item.taxi.firstName} ${data.item.taxi.lastName}` }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taxi.licenceNum }}
                </b-card-text>
              </template>
              <template #cell(vehicle)="data">
                <template v-if="data.item.vehicle">
                  <b-card-text class="font-weight-bold mb-25">
                    {{
                      `${data.item.vehicle.manufacturer} - ${data.item.vehicle.model}`
                    }}
                  </b-card-text>
                  <b-card-text class="text-nowrap">
                    {{ data.item.vehicle.regNum }}
                  </b-card-text>
                </template>
              </template>
              <template #cell(rate)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ formatCurrency(data.item.taxiRate) }}
                </b-card-text>
              </template>
            </b-table-lite>

            <b-card-body class="invoice-padding">
              <b-row>
                <!-- Notes -->
                <b-col md="6">
                  <b-card-text>
                    <h6>Notes</h6>
                    <p>{{ booking.notes }}</p>
                  </b-card-text>
                </b-col>

                <!-- Booking Fee -->
                <b-col
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                >
                  <div class="invoice-total-wrapper">
                    <!-- Taxi Rates -->
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Taxi Rate:</p>
                      <p class="invoice-total-amount">£{{ taxiRateAmount }}</p>
                    </div>

                    <!-- GTA Office Fee -->
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">GTA Rate:</p>
                      <p class="invoice-total-amount">
                        {{ formatCurrency(booking.officeFee) }}
                      </p>
                    </div>

                    <!-- Baggage Fee -->
                    <div
                      v-if="booking.heavyBaggageKgTotalFee"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">Baggage Fee:</p>
                      <p class="invoice-total-amount text-muted">
                        {{ formatCurrency(booking.heavyBaggageKgTotalFee) }}
                      </p>
                    </div>

                    <!-- Meter Reader Fee -->
                    <div
                      v-if="booking.meterReaderTotalFee"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">Meter Reader Fee:</p>
                      <p class="invoice-total-amount text-muted">
                        {{ formatCurrency(booking.meterReaderTotalFee) }}
                      </p>
                    </div>

                    <!-- Wait Time Fee -->
                    <div
                      v-if="booking.waitingTimeTotalFee"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">Wait Time Fee:</p>
                      <p class="invoice-total-amount text-muted">
                        {{ formatCurrency(booking.waitingTimeTotalFee) }}
                      </p>
                    </div>

                    <!-- Wheelchair Fee -->
                    <div
                      v-if="booking.wheelchairTotalFee"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">Wheelchair Fee:</p>
                      <p class="invoice-total-amount text-muted">
                        {{ formatCurrency(booking.wheelchairTotalFee) }}
                      </p>
                    </div>

                    <!-- Adjustment Fee -->
                    <div
                      v-if="booking.bookingAdjustmentFee"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">Adjustment:</p>
                      <p class="invoice-total-amount text-muted">
                        {{ formatCurrency(booking.bookingAdjustmentFee) }}
                      </p>
                    </div>

                    <!-- Booking Additional Fees -->
                    <template v-if="booking.bookingAdditionalFees">
                      <div
                        v-for="additionalFee in booking.bookingAdditionalFees"
                        :key="additionalFee.id"
                        class="invoice-total-item"
                      >
                        <p class="invoice-total-title">
                          {{ additionalFee.bookingFeeTypeName }}
                        </p>
                        <p class="invoice-total-amount text-muted">
                          {{
                            formatCurrency(
                              additionalFee.gtaFeeAmount > 0
                                ? additionalFee.gtaFeeAmount
                                : additionalFee.taxiFeeAmount
                            )
                          }}
                        </p>
                      </div>
                    </template>

                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title font-weight-bolder">
                        Total
                      </p>
                      <p class="invoice-total-amount">
                        {{ formatCurrency(booking.totalBookingFee) }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col
          :lg="
            booking.bookingStatusId !== BOOKING_STATUS_EDITED_ID &&
            booking.bookingStatusId !== BOOKING_STATUS_CANCELLED_ID
              ? 4
              : 12
          "
        >
          <b-col
            class="px-0 py-0"
            v-if="
              booking.bookingStatusId !== BOOKING_STATUS_EDITED_ID &&
              booking.bookingStatusId !== BOOKING_STATUS_CANCELLED_ID
            "
          >
            <b-card>
              <b-button
                variant="outline-primary"
                class="mb-75"
                block
                :to="{ name: 'booking-edit', params: { id: $route.params.id } }"
              >
                Edit
              </b-button>

              <b-button
                variant="outline-primary"
                class="mb-75"
                block
                @click="changeStatus"
              >
                {{
                  booking.bookingStatusId === 1
                    ? 'Mark as Pending'
                    : 'Mark as Paid'
                }}
              </b-button>

              <b-button
                variant="outline-primary"
                class="mb-75"
                block
                @click="printBooking"
              >
                Print
              </b-button>

              <b-button
                variant="outline-secondary"
                class="mb-75"
                block
                @click="openBookingAttachmentFormModal"
              >
                Attach files
              </b-button>

              <b-button
                variant="danger"
                class="mb-75"
                block
                @click="cancel"
              >
                Cancel
              </b-button>
            </b-card>
          </b-col>

          <b-col class="px-0 py-0">
            <booking-attach-list />
          </b-col>
        </b-col>
      </b-row>
      <booking-attach-modal ref="bookingAttachmentFormRef" />
    </template>
  </div>
</template>

<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BLink,
  BListGroup,
  BListGroupItem,
  BRow,
  BTableLite,
  VBToggle,
  BTooltip,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import BookingAttachList from '@/modules/booking/components/BookingAttachList.vue';
import BookingAttachModal from '@/modules/booking/components/BookingAttachModal.vue';
import router from '@/router';
import Swal from 'sweetalert2';
import useBooking from '@/modules/booking/composables/useBooking';
import useBookingAttachment from '@/modules/booking/composables/useBookingAttachment';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import useUtils from '@/composables/useUtils';

export default {
  setup() {
    const { getBooking, changeBookingStatus, cancelBooking, downloadBooking } =
      useBooking();
    const { getBookingAttachments } = useBookingAttachment();
    const {
      BOOKING_STATUS_EDITED_ID,
      BOOKING_STATUS_CANCELLED_ID,
      getBookingStatusVariant,
      getBookingTypeVariantAndName,
    } = useBookingUtils();
    const { formatDateDdMmYyyyHi, convertUtcToLocalDate, formatCurrency } =
      useUtils();

    const booking = ref(null);
    const taxiRateAmount = ref(0);
    const bookingAttachmentFormRef = ref(null);

    const openBookingAttachmentFormModal = () => {
      bookingAttachmentFormRef.value.$refs.refModal.show();
    };

    const dateFormated = (date) =>
      formatDateDdMmYyyyHi(convertUtcToLocalDate(date));
    const getTaxiRateAmount = (taxiArray) => {
      const result = taxiArray.reduce(
        (total, taxi) => total + taxi.taxiRate,
        0
      );
      return result.toFixed(2);
    };

    const initData = async () => {
      await getBookingAttachments(router.currentRoute.params.id);
      const data = await getBooking(router.currentRoute.params.id);
      booking.value = data;
      taxiRateAmount.value = getTaxiRateAmount(data.bookingTaxis);
    };

    const changeStatus = async () => {
      const { value } = await Swal.fire({
        title: 'Change Booking Status',
        text: 'This action will change the status of all linked transactions',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, continue!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await changeBookingStatus(booking.value.id);
      const data = await getBooking(router.currentRoute.params.id);
      booking.value = data;
    };

    const printBooking = async () => {
      const response = await downloadBooking(booking.value.id);

      if (response !== null) {
        // create file link in browser's memory
        const url = URL.createObjectURL(response.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    };

    const cancel = async () => {
      const { value } = await Swal.fire({
        title: 'Cancellation Confirmation',
        text: 'This action will change the status of all linked transactions to "Cancelled".',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, continue!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await cancelBooking(booking.value.id);
      const data = await getBooking(router.currentRoute.params.id);
      booking.value = data;
    };

    initData();
    return {
      // Const
      BOOKING_STATUS_EDITED_ID,
      BOOKING_STATUS_CANCELLED_ID,

      // Reactive
      booking,
      bookingAttachmentFormRef,
      taxiRateAmount,

      //Method
      changeStatus,
      dateFormated,
      formatCurrency,
      getBookingStatusVariant,
      getBookingTypeVariantAndName,
      openBookingAttachmentFormModal,
      printBooking,
      cancel,
    };
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BCol,
    BDropdown,
    BDropdownItem,
    BLink,
    BListGroup,
    BListGroupItem,
    BookingAttachList,
    BookingAttachModal,
    BRow,
    BTableLite,
    VBToggle,
    BTooltip,
  },
};
</script>

<style lang="scss" scoped>
.booking-wrap {
  max-width: 400px;
}

.booking-label {
  max-width: 8rem;
}

@import '~@core/scss/base/pages/app-invoice.scss';
</style>
