import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useBookingAttachment = () => {
  const toast = useToast();

  const getBookingAttachments = async (id) => {
    try {
      return await store.dispatch(
        'bookingAttachment/getBookingAttachmentList',
        id
      );
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Booking not found',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const uploadBookingAttachments = async (bookingId, bookingAttachments) => {
    try {
      return await store.dispatch('bookingAttachment/uploadFiles', {
        bookingId,
        bookingAttachments,
      });
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const deleteBookingAttachment = async (id) => {
    try {
      await store.dispatch('bookingAttachment/deleteFile', id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const downloadBookingAttachment = async (id) => {
    try {
      return await store.dispatch('bookingAttachment/downloadFile', id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Method
    deleteBookingAttachment,
    downloadBookingAttachment,
    getBookingAttachments,
    uploadBookingAttachments,
  };
};

export default useBookingAttachment;
